@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #F3F4F6;
}
.skillscroll {
    scrollbar-width: thin;
  scrollbar-color:  #4f46e5 #f3f4f6;
}
body::-webkit-scrollbar {
    width: 0.5rem;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: rgb(79,70,229);
    outline: 1px solid rgb(79,70,229);
  }
  .skillscroll::-webkit-scrollbar {
    width: 0.15rem;
  }
   
  .skillscroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  .skillscroll::-webkit-scrollbar-thumb {
    background-color: rgb(79,70,229);
    outline: 1px solid rgb(79,70,229);
  }
  
  .card{
    
    position: relative;
    width: 300px;
    height: 300px;
    background: #fff;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow:0 2px 10px rgba(0,0,0,.2);
  }
  .card:before,
  .card:after
  {
    content:"";
    position: absolute;
    
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    transition: 0.5s;
    z-index:  -1;
  }
  .card:hover:before{
  transform: rotate(20deg);
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  }
  .card:hover:after{
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0,0,0,.2);
  }
  .card .imgBx{
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #222;
  transition: 0.5s;
  z-index: 1;
  }
  
  .card:hover .imgBx
  {
    bottom: 80px;
  }

  .card .imgBx img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .card .details{
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      height: 60px;
      text-align: center;
  }

  .card .details h2{
   margin: 0;
   padding: 0;
   font-weight: 600;
   font-size: 20px;
   color: #777;
   text-transform: uppercase;
  } 

  .card .details h2 span{
  font-weight: 500;
  font-size: 16px;
  color:rgb(79,70,229);
  display: block;
  margin-top: 5px;
  cursor: pointer;
   } 

.dummyProject {
  width: 300px;
    height: 300px;
  
}